import React, { Component } from 'react';

class FeatureRight extends Component {
    constructor(props){
        super(props);
        this.state = {
            collRight: [
                {
                    id: 1,
                    icon: 'fa fa-check',
                    title: 'Proyecto Pedal',
                    text: 'Este proyecto promueve un trans- porte sostenible mediante la creación de ciclovías seguras y bien mantenidas, contribuyendo a la reducción del tráfico y la contami- nación, y fomentando un estilo de vida activo y ecológico.'
                },
                {
                    id: 2,
                    icon: 'fa fa-check',
                    title: 'Madres en Marcha',
                    text: 'Con un enfoque en el apoyo integral a las madres solteras, esta propuesta incluye acceso a oportu- nidades de empleo y servicios de formación profesional, además de cuidado infantil, para empoderar a estas mujeres a alcanzar su pleno potencial.'
                },
                // {
                //     id: 3,
                //     icon: 'fa fa-check',
                //     title: 'Talleres y Cursos en Armonización Contable',
                //     text: 'Formación continua en la unificación de criterios contables, asegurando transparencia en las cuentas públicas.'
                // }
            ]
        }
    }

    render() {
        return (
            <div className="topic-items">
                {
                    this.state.collRight.map((data,i)=> (
                        <div className="topic-item" key={i}>
                            <div className="icon-box">
                                <i className={data.icon}></i>
                            </div>
                            <div className="topic-content">
                                <h4>{data.title}</h4>
                                <p>{data.text}</p>
                            </div>
                        </div>
                    
                    ))
            }
            </div>
        );
    }

}

export default FeatureRight;
