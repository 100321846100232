import React, { Component } from 'react';

class ContainerLeft extends Component {
    constructor(props){
        super(props);
        this.state = {
            collLeft: [
                {
                    id: 1,
                    title: 'Desarrollo Comunitario',
                    text: 'Promoción de programas que fortalecen la unidad familiar, el progreso y la seguridad, reflejando un enfoque en la reconstrucción y la mejora continua de la sociedad.'
                },
                {
                    id: 2,
                    title: 'Gestión Pública',
                    text: 'Implementación de políticas y estrategias que aseguran un crecimiento sostenible y equitativo, promoviendo la inclusión y el bienestar económico.'
                },
                {
                    id: 3,
                    title: 'Asesoría Estratégica',
                    text: 'Asesoramiento en la definición de políticas públicas que se alinean con las necesidades y valores de la comunidad, siempre con la mira en la transformación positiva de APAN.'
                }
            ]
        }
    }
    render() {
        return (
            <div className="chapter-list border-right-0">
                 {
                    this.state.collLeft.map((data,i) => (
                        <div className="chapter-item" key={i}>
                            <h4><span>{data.subtitle} </span> {data.title}</h4>
                            <p>{data.text} </p>
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default ContainerLeft;
