import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Author extends Component {
    constructor(props){
        super(props);
        this.state = {
            listItem: [
                {
                    id: 1,
                    icon: 'fa fa-check',
                    title: 'Liderazgo Comprometido',
                },
                {
                    id: 2,
                    icon: 'fa fa-check',
                    title: 'Gestión Innovadora',
                },
                {
                    id: 3,
                    icon: 'fa fa-check',
                    title: 'Educación y Desarrollo Continuo',
                },
            ]
        }
    }
    render() {
        return (
            <section className="author-section section-padding bg-white" id="author">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="section-heading center-heading text-center mb-60">
                                <h3 className="heading-title">Eficiencia y Efectividad en la Gestión Pública</h3>
                                <p>Un liderazgo que une tradición y visión de futuro.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-5 col-md-6">
                            <div className="auhtor-img mb-4 mb-lg-0">
                                <img src="assets/images/bg/about.jpg" alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 ps-4">
                            <div className="section-heading mb-0">
                                <h3 className="heading-title">María Gloria Hernández Madrid</h3>
                                <p className="lead">Soy María Gloria Hernández Madrid, comprometida con la transformación de APAN hacia 
                                un futuro próspero y sostenible. Con una trayectoria dedicada al desarrollo comunitario y la 
                                implementación de políticas que generan progreso, busco asegurar que cada acción beneficie a toda 
                                nuestra comunidad y fortalezca la confianza en nuestras instituciones.</p>
                            </div>

                            <div className="author-desc">
                                <ul className="list-unstyled">
                                    {
                                    this.state.listItem.map((data,i) => (
                                        <li key={data.id}><i className={data.icon}></i> {data.title}</li>
                                        ))
                                    }
                                </ul>
                                <p>Sígueme en</p>
                                <ul>
                                    <li><Link to="https://www.facebook.com/GloriaHernandezMadrid"> Facebook</Link></li>
                                    <li><Link to="https://www.instagram.com/gloriahdezm/">Instagram</Link></li>
                                    {/* <li><Link to="https://www.tiktok.com/@marlene.puertas?_t=8jh3VuuEPkk&_r=1">Tik Tok</Link></li>
                                    <li><Link to="https://x.com/marlene_puertas?t=iFRQ536vclBwdIlywQTN8w&s=09">X</Link></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
        );
    }
}

export default Author;
