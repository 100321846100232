import React, { Component } from 'react';
import {Link} from 'react-router-dom'

class Intro extends Component {
    render() {
        return (
            <section className="pt-100 center-about bg-grey">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="section-heading text-center">
                                {/* <span>Descubre el impacto de un servicio público innovador y dedicado a la mejora de la calidad de vida en APAN.</span> */}
                                <h2>Descubre el impacto de un servicio público innovador y dedicado a la mejora de la calidad de vida en APAN.</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Intro;
