import React, { Component } from 'react';

class FeatureLeft extends Component {
    constructor(props){
        super(props);
        this.state = {
            collLeft: [
                {
                    id: 1,
                    icon: 'fa fa-check',
                    title: 'Impulso Joven',
                    text: 'Esta iniciativa busca crear oportu- nidades laborales para jóvenes, ofreciéndoles las herramientas necesarias para su inserción en el mercado de trabajo y fomentando su desarrollo profesional a través de programas de capacitación y vínculos con el sector empresarial'
                },
                {
                    id: 2,
                    icon: 'fa fa-check',
                    title: 'Ruta Rosa',
                    text: 'Priorizando la seguridad en el transporte público, especialmente para las mujeres, “Ruta Rosa” imple- mentará rutas seguras y accesibles, garantizando viajes libres de preo- cupaciones para las mujeres de nuestra comunidad.'
                },
                // {
                //     id: 3,
                //     icon: 'fa fa-check',
                //     title: 'Madres en Marcha',
                //     text: 'Con un enfoque en el apoyo integral a las madres solteras, esta propuesta incluye acceso a oportu- nidades de empleo y servicios de formación profesional, además de cuidado infantil, para empoderar a estas mujeres a alcanzar su pleno potencial.'
                // }
            ]
        }
    }

    render() {
        return (
            <div className="topic-items">
            {
                    this.state.collLeft.map((data,i) => (
                        <div className="topic-item" key={i}>
                            <div className="icon-box">
                                <i className={data.icon}></i>
                            </div>
                            <div className="topic-content">
                                <h4>{data.title}</h4>
                                <p>{data.text}</p>
                            </div>
                        </div>
                    
                    ))
            }
            </div>
        );
    }

}

export default FeatureLeft;
