import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class About extends Component {
    constructor(props){
        super(props);
        this.state = {
            about: [
                {
                    id: 1,
                    icon: 'bi bi-badge2',
                    title: 'Perfil Profesional',
                    text: 'Más de una década impulsando el cambio social y económico en roles clave de la administración pública, siempre con un enfoque en la sostenibilidad y equidad.'
                },
                {
                    id: 2,
                    icon: 'bi bi-support',
                    title: 'Trayectoria Destacada',
                    text: 'Participación en iniciativas estratégicas para la transformación económica y social de APAN, con un enfoque en la creación de empleos, el apoyo a las familias y el fortalecimiento de la comunidad.'
                },
                {
                    id: 3,
                    icon: 'bi bi-rocket2',
                    title: 'Compromiso con la Excelencia',
                    text: 'Liderazgo basado en valores de lealtad a la patria, integridad y la voluntad de servir a la comunidad, asegurando el progreso sostenible y la inclusión de todas las voces en el diálogo público.'
                }
            ]
        }
    }
    render() {
        return (
        <section className="about-section section-padding bg-grey" id="about">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="about-img">
                            <img src="assets/images/banner/book.jpg" alt="" className="img-fluid"/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="section-heading">
                            <span className="subheading">Acerca de </span>
                            <h3 className="heading-title">Experiencia en desarrollo y políticas públicas</h3>
                            <p>Profesional con una sólida trayectoria en la promoción de la unidad familiar, la seguridad y la reconstrucción del tejido social. Apoyando políticas que generan progreso y bienestar para todos en APAN.</p>
                        </div>

                        {
                            this.state.about.map((data,i) => (
                                <div className="about-text-block" key={i}>
                                    <i className={data.icon}></i>
                                    <h4>{data.title}</h4>
                                    <p>{data.text} </p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </section>  
        );
    }
}

export default About;
