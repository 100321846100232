import React, { Component } from 'react';

class ContainerMid extends Component {
    constructor(props){
        super(props);
        this.state = {
            collMid: [
                {
                    id: 1,
                    title: 'Innovación y Eficiencia',
                    text: 'Compromiso con la innovación y la eficiencia en la administración pública, promoviendo reformas que llevan a una mejor gestión de los recursos y servicios.'
                },
                {
                    id: 2,
                    title: 'Formación Continua',
                    text: 'Dedicación al desarrollo profesional continuo, participando en capacitaciones y talleres para estar al frente de las mejores prácticas en la administración pública.'
                },
                {
                    id: 3,
                    title: 'Liderazgo y Gestión',
                    text: 'Dirección de equipos multidisciplinarios con el fin de alcanzar la excelencia en servicios y gestión pública, con un enfoque en la eficiencia y la transparencia.'
                }
            ]
        }
    }
    render() {
        return (
            <div className="chapter-list border-right-0">
                 {
                    this.state.collMid.map((data,i) => (
                        <div className="chapter-item" key={i}>
                            <h4><span>{data.subtitle} </span> {data.title}</h4>
                            <p>{data.text} </p>
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default ContainerMid;
